import React from 'react'

const Logo = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 331.5 218.9" xmlSpace="preserve" fill={'#f99f79'}>
    <path className="st0" d="M205.5,2h-11.2c-11.6,0-22.3,6.4-27.8,16.7l-0.5-0.5C160.5,8.2,149.9,2,138.5,2h-11.2
	c-17.4,0-31.5,14.1-31.5,31.5v67.8c0,17.4,14.1,31.5,31.5,31.5h11.2c1.9,0,3.5-1.6,3.5-3.5s-1.6-3.5-3.5-3.5h-11.2
	c-13.6,0-24.5-11-24.5-24.5V33.5c0-13.5,11-24.5,24.5-24.5h11.2c9.7,0,18.4,5.7,22.4,14.5c-16.7,17.8-26.3,30.1-30.9,39.9
	c-4.9,10.4-4.5,18.6,1.4,25.9c3.3,4.1,6.2,6.3,14.2,12.1l3.7,2.8c1.6,1.2,3.7,0.8,4.9-0.7c1.2-1.6,0.8-3.7-0.7-4.9l-3.8-2.8
	c-7.8-5.8-10.2-7.5-12.9-10.9c-3.4-4.2-4.5-9.2-0.9-17.4l26.9,21.4v12.4c0,17.4,14.1,31.5,31.5,31.5h11.2
	c17.4,0,31.5-14.1,31.5-31.5V33.5C237,16.1,222.9,2,205.5,2z M162.8,33.5v22.2l-12.3-10c3.5-4.2,7.5-8.9,12.3-14
	C162.8,32.3,162.8,32.9,162.8,33.5L162.8,33.5z M169.8,64.8l16.6-13.6c2.4,3.2,4.7,6.6,6.6,10.2l-23.2,18.5L169.8,64.8z M169.8,55.8
	V34.9c0.2-0.4,0.3-0.9,0.3-1.4c0-0.4,0-0.9,0-1.3c4.7,5,8.7,9.5,12,13.5L169.8,55.8z M146.1,51.3l16.6,13.6V80l-23.5-18.8
	C141.3,57.8,143.7,54.4,146.1,51.3L146.1,51.3z M230,101.4c0,13.5-11,24.5-24.5,24.5h-11.2c-13.6,0-24.5-11-24.5-24.5V88.9L195.9,68
	c2.6,8.1,0.3,13.1-2.9,17.5c-2.8,3.9-7.6,7.8-11.4,10.9c-1,0.8-1.9,1.5-2.7,2.2c-1.5,1.2-1.8,3.4-0.6,4.9s3.4,1.8,4.9,0.6
	c0.1,0,0.1-0.1,0.1-0.1l2.6-2.1c4.1-3.4,9.3-7.6,12.6-12.2c11.6-16.1,4.5-33.5-27-65.7c3.9-9,12.7-14.9,22.6-14.9h11.2
	C219,9,230,20,230,33.5V101.4z"/>
    <g>
      <path className="st0" d="M53.5,209.2c0.2,0.9,0.6,1.6,1.3,2.4c0.7,0.7,1.1,1.3,1.1,1.6v0.2c0,0.5-0.4,0.8-1.3,0.8h-8.9
		c-0.9,0-1.3-0.3-1.3-0.8v-0.2c0-0.3,0.4-0.8,1.1-1.6c0.8-0.7,1-1.5,0.9-2.4l-4.6-26.4l-10.9,29.9c-0.4,0.9-0.9,1.4-1.7,1.4
		c-0.9,0-1.5-0.5-1.9-1.4L15.9,182l-4.4,27.2c-0.2,0.9,0.2,1.7,1,2.4c0.8,0.7,1.2,1.2,1.2,1.6v0.2c0,0.5-0.4,0.8-1.2,0.8h-6
		c-0.8,0-1.2-0.3-1.2-0.8v-0.2c0-0.4,0.4-0.9,1.1-1.6c0.7-0.7,1.1-1.5,1.3-2.4l5.3-31.7c0.1-0.9-0.2-1.6-0.9-2.4
		c-0.7-0.7-1.1-1.2-1.1-1.6v-0.2c0-0.6,0.4-0.9,1.1-0.9h5.2c1.3,0,2.2,0.6,2.6,1.7l10.5,30.5l10.6-30.5c0.4-1.1,1.2-1.7,2.4-1.7h5.5
		c0.7,0,1.1,0.3,1.1,0.8v0.2c0,0.4-0.2,0.7-0.6,1.1c-0.4,0.4-0.8,0.8-1.1,1.3c-0.3,0.5-0.4,1.2-0.3,1.9L53.5,209.2z"/>
      <path className="st0" d="M82.2,208.9c0.1,0.9,0.3,1.5,0.7,2c0.4,0.5,0.7,0.8,1.1,0.9c0.4,0.1,0.7,0.3,1,0.5s0.4,0.5,0.4,0.8
		c0,1-1.6,1.5-4.8,1.5c-1.6,0-2.7-0.2-3.4-0.7c-0.7-0.5-1.1-1.4-1.3-2.8c-1.6,2.5-4.3,3.8-8.2,3.8c-3.3,0-5.9-0.8-8-2.3
		c-2-1.5-3-3.7-3-6.6c0-2.8,1-4.9,3-6.5c2-1.5,4.6-2.3,7.7-2.3c2.8,0,5.1,0.7,6.8,2l-0.1-1.2c-0.4-3.6-1-6.2-1.9-7.9
		c-0.9-1.6-2.3-2.5-4.3-2.5c-1.2,0-2.2,0.4-3,1.1c-0.8,0.7-1.4,1.6-1.6,2.4s-0.7,1.7-1.3,2.4c-0.5,0.7-1.2,1.1-2.1,1.1
		c-0.9,0-1.6-0.2-2.1-0.7c-0.5-0.5-0.8-1.1-0.8-2c0-1.9,1-3.4,3.1-4.7c2.1-1.3,4.7-1.9,7.8-1.9c3.7,0,6.7,1.1,8.9,3.3
		c2.3,2.2,3.6,5.2,4.1,9L82.2,208.9z M69.7,212.5c1.6,0,2.9-0.6,3.9-1.8c1-1.2,1.5-2.6,1.4-4.2l-0.2-1.8c-0.1-1.7-0.7-3-1.7-4
		c-1-1-2.3-1.5-3.9-1.5c-1.6,0-3,0.5-4,1.6c-1,1-1.5,2.4-1.5,4.2c0,2.1,0.6,3.8,1.7,5.3C66.5,211.8,68,212.5,69.7,212.5z"/>
      <path className="st0" d="M113.5,210.3c0.3,0.4,0.8,0.8,1.7,1.4c0.9,0.5,1.3,1.1,1.3,1.5v0.2c0,0.5-0.4,0.8-1.1,0.8H106
		c-0.7,0-1-0.3-1-0.9v-0.1c0-0.4,0.3-0.8,0.9-1.3c0.6-0.5,0.7-1,0.3-1.6l-7.5-10.1l-4,3.7v5.3c0,0.8,0.4,1.6,1.2,2.3
		c0.8,0.7,1.2,1.2,1.2,1.6v0.2c0,0.6-0.4,0.9-1.3,0.9h-8.9c-0.8,0-1.2-0.3-1.2-0.9v-0.2c0-0.4,0.4-0.9,1.1-1.6
		c0.7-0.7,1.1-1.4,1.1-2.2v-31c0-0.9-0.4-1.8-1.2-2.5c-0.8-0.7-1.2-1.2-1.2-1.5v-0.2c0-0.4,0.3-0.7,1-1.1l6.2-2.9
		c0.7-0.3,1.2-0.3,1.6-0.1c0.4,0.2,0.6,0.6,0.6,1v29l10.6-9.2c0.6-0.5,0.6-1.3-0.2-2.1s-1.1-1.4-1.1-1.7v-0.2c0-0.6,0.4-0.9,1.1-0.9
		h7.5c0.7,0,1,0.3,1,0.8v0.2c0,0.4-0.2,0.8-0.7,1.1c-0.5,0.4-1.1,0.8-1.9,1.3c-0.8,0.5-1.5,1-2,1.4l-5.8,5.3L113.5,210.3z"/>
      <path className="st0" d="M138,205.6c0.3-0.4,0.7-0.6,1.2-0.5c0.5,0.1,0.7,0.5,0.7,1.1c-0.3,2.1-1.5,4-3.6,5.9c-2.1,1.8-4.9,2.8-8.3,2.8
		c-4,0-7.3-1.4-10-4.1c-2.6-2.7-4-6.3-4-10.8c0-4.2,1.4-7.6,4.1-10.3c2.7-2.7,6-4.1,10-4.1c3.5,0,6.2,0.9,8.1,2.6
		c1.9,1.7,2.9,3.8,2.9,6c0,2-1,3.4-3,4.1l-14.2,5.2c0.7,2,1.9,3.6,3.4,4.8c1.5,1.1,3.3,1.7,5.3,1.7c1.8,0,3.3-0.4,4.6-1.2
		C136.4,207.8,137.4,206.8,138,205.6z M126.9,187.6c-1.8,0-3.3,0.9-4.4,2.6c-1.1,1.8-1.6,4.1-1.6,6.9c0,1.3,0.1,2.6,0.4,3.8l9.8-4.2
		c1-0.5,1.5-1.3,1.5-2.5c0-1.4-0.5-2.9-1.6-4.4C129.8,188.4,128.5,187.6,126.9,187.6z"/>
      <path className="st0" d="M153.5,213.4v-0.2c0-0.3,0.4-0.8,1.1-1.5c0.7-0.7,1.1-1.5,1.1-2.3v-32c0-0.9-0.4-1.6-1.1-2.3
		c-0.7-0.7-1.1-1.2-1.1-1.6v-0.2c0-0.5,0.4-0.8,1.2-0.8h9.2c0.8,0,1.2,0.3,1.2,0.8v0.2c0,0.4-0.4,0.9-1.2,1.6
		c-0.8,0.7-1.2,1.5-1.2,2.3v32c0,0.9,0.4,1.6,1.2,2.3c0.8,0.7,1.2,1.2,1.2,1.5v0.2c0,0.5-0.4,0.8-1.2,0.8h-9.2
		C154,214.2,153.5,213.9,153.5,213.4z"/>
      <path className="st0" d="M179,214.8c-2.5,0-4.6-0.8-6-2.4c-1.5-1.6-2.2-4-2.2-7.1v-16.4h-3.4c-0.7,0-1-0.3-1-1V187c0-0.7,0.3-1.1,1-1.1
		h1.8c1.5,0,2.7-0.5,3.6-1.5c0.9-1,1.5-2.4,1.7-4.2c0.1-0.9,0.5-1.3,1.2-1.3h0.9c0.7,0,1.1,0.4,1.1,1.3l0.1,5.7h7.4
		c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8v0.8c0,0.7-0.4,1-1.1,1h-7.4v16.5c0,1.4,0.3,2.4,0.9,3.2c0.6,0.8,1.4,1.1,2.4,1.1
		c2.4,0,3.7-1,4.2-3.1c0.2-0.3,0.4-0.5,0.7-0.6c0.3-0.1,0.6,0,0.9,0.1c0.3,0.2,0.4,0.4,0.4,0.8c0,2.1-0.8,4-2.3,5.5
		C183.5,214,181.5,214.8,179,214.8z"/>
      <path className="st0" d="M201.3,214.2c-0.9,0-1.3-0.3-1.3-0.9v-0.2c0-0.3,0.4-0.8,1.1-1.5c0.7-0.7,1.1-1.5,1.1-2.3v-32
		c0-0.8-0.4-1.6-1.1-2.3c-0.7-0.7-1.1-1.2-1.1-1.5v-0.2c0-0.5,0.4-0.8,1.3-0.8h12.2c5.5,0,10,1.1,13.4,3.3c3.4,2.2,5.2,5.6,5.2,10.3
		c0,4.5-1.5,8-4.6,10.5c-3,2.5-7.2,3.8-12.5,3.8h-5.6v9c0,0.9,0.4,1.6,1.2,2.3c0.8,0.7,1.2,1.2,1.2,1.5v0.2c0,0.6-0.4,0.9-1.2,0.9
		H201.3z M211.7,197.4h3.4c3,0,5.4-0.9,7.1-2.6s2.6-4.1,2.6-7c0-3.9-1.1-7-3.3-9.1c-2.2-2.2-4.9-3.2-8-3.2h-2.4
		c-1.1,0-1.7,0.7-1.7,2.2V195C209.4,196.6,210.2,197.4,211.7,197.4z"/>
      <path className="st0" d="M234.5,214.2c-0.8,0-1.2-0.3-1.2-0.9v-0.2c0-0.4,0.4-0.9,1.1-1.6c0.7-0.7,1.1-1.4,1.1-2.2v-31
		c0-0.9-0.4-1.8-1.1-2.5c-0.7-0.7-1.1-1.2-1.1-1.5v-0.2c0-0.4,0.3-0.7,1-1.1l5.9-2.9c0.7-0.3,1.2-0.3,1.6-0.1c0.4,0.2,0.6,0.6,0.6,1
		v38.2c0,0.8,0.4,1.6,1.2,2.3c0.8,0.7,1.2,1.2,1.2,1.6v0.2c0,0.6-0.4,0.9-1.3,0.9H234.5z"/>
      <path className="st0" d="M272.1,208.9c0.1,0.9,0.3,1.5,0.7,2c0.4,0.5,0.7,0.8,1.1,0.9c0.4,0.1,0.7,0.3,1,0.5s0.4,0.5,0.4,0.8
		c0,1-1.6,1.5-4.8,1.5c-1.6,0-2.7-0.2-3.4-0.7c-0.7-0.5-1.1-1.4-1.3-2.8c-1.6,2.5-4.3,3.8-8.2,3.8c-3.3,0-5.9-0.8-8-2.3
		c-2-1.5-3-3.7-3-6.6c0-2.8,1-4.9,3-6.5c2-1.5,4.6-2.3,7.7-2.3c2.8,0,5.1,0.7,6.8,2l-0.1-1.2c-0.4-3.6-1-6.2-1.9-7.9
		c-0.9-1.6-2.3-2.5-4.3-2.5c-1.2,0-2.2,0.4-3,1.1c-0.8,0.7-1.4,1.6-1.6,2.4s-0.7,1.7-1.3,2.4c-0.5,0.7-1.2,1.1-2.1,1.1
		c-0.9,0-1.6-0.2-2.1-0.7c-0.5-0.5-0.8-1.1-0.8-2c0-1.9,1-3.4,3.1-4.7c2.1-1.3,4.7-1.9,7.8-1.9c3.7,0,6.7,1.1,8.9,3.3
		c2.3,2.2,3.6,5.2,4.1,9L272.1,208.9z M259.6,212.5c1.6,0,2.9-0.6,3.9-1.8s1.5-2.6,1.4-4.2l-0.2-1.8c-0.1-1.7-0.7-3-1.7-4
		c-1-1-2.3-1.5-3.9-1.5c-1.6,0-3,0.5-4,1.6c-1,1-1.5,2.4-1.5,4.2c0,2.1,0.6,3.8,1.7,5.3C256.4,211.8,257.8,212.5,259.6,212.5z"/>
      <path className="st0" d="M304.9,209.3c0,0.9,0.4,1.6,1.1,2.3c0.7,0.7,1,1.2,1,1.6v0.2c0,0.6-0.4,0.9-1.3,0.9h-8.6
		c-0.9,0-1.3-0.3-1.3-0.9v-0.2c0-0.4,0.4-0.9,1.1-1.6c0.7-0.7,1.1-1.5,1-2.3l-0.6-11.3c-0.3-6.5-2.3-9.7-6.2-9.7
		c-1.8,0-3.3,0.7-4.5,2c-1.1,1.4-1.7,3.1-1.7,5.3v13.8c0,0.9,0.4,1.6,1.2,2.3c0.8,0.7,1.2,1.2,1.2,1.5v0.2c0,0.6-0.4,0.9-1.3,0.9
		H277c-0.8,0-1.2-0.3-1.2-0.9v-0.2c0-0.4,0.4-0.9,1.2-1.5c0.8-0.7,1.2-1.4,1.2-2.3v-15.8c0-0.7-0.2-1.4-0.7-1.8
		c-0.5-0.5-0.9-0.9-1.3-1.1s-0.6-0.6-0.6-1v-0.2c0-0.4,0.4-0.7,1.1-1.1l5.7-2.8c0.6-0.3,1.1-0.3,1.4-0.1c0.3,0.2,0.4,0.6,0.4,1.1
		v3.8c0.8-1.6,2-2.9,3.6-3.8c1.6-0.9,3.4-1.4,5.3-1.4c3,0,5.6,1,7.6,3.1c2.1,2.1,3.3,5.3,3.5,9.6L304.9,209.3z"/>
      <path className="st0" d="M319.1,214.8c-2.5,0-4.6-0.8-6-2.4c-1.5-1.6-2.2-4-2.2-7.1v-16.4h-3.4c-0.7,0-1-0.3-1-1V187
		c0-0.7,0.3-1.1,1-1.1h1.8c1.5,0,2.7-0.5,3.6-1.5c0.9-1,1.5-2.4,1.7-4.2c0.1-0.9,0.5-1.3,1.2-1.3h0.9c0.7,0,1.1,0.4,1.1,1.3l0.1,5.7
		h7.4c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8v0.8c0,0.7-0.4,1-1.1,1h-7.4v16.5c0,1.4,0.3,2.4,0.9,3.2
		c0.6,0.8,1.4,1.1,2.4,1.1c2.4,0,3.7-1,4.2-3.1c0.2-0.3,0.4-0.5,0.7-0.6c0.3-0.1,0.6,0,0.9,0.1c0.3,0.2,0.4,0.4,0.4,0.8
		c0,2.1-0.8,4-2.3,5.5C323.6,214,321.6,214.8,319.1,214.8z"/>
    </g>
  </svg>
)

export default Logo