import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'

import Home from './views/Home'
import Privacy from './views/Privacy'
import Quizo from './views/Quizo'
import QuizoPrivacy from './views/QuizoPrivacy'
import Manifestor from './views/Manifestor'
import ManifestorPrivacy from './views/ManifestorPrivacy'

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={<Home />}
        />
        <Route
          path='/makeitplant'
          element={<Home />}
        />
        <Route
          path='/makeitplant/privacy'
          element={<Privacy />}
        />
        <Route
          path='/quizo'
          element={<Quizo />}
        />
        <Route
          path='/quizo/privacy'
          element={<QuizoPrivacy />}
        />
        <Route
          path='/manifestor'
          element={<Manifestor />}
        />
        <Route
          path='/manifestor/privacy'
          element={<ManifestorPrivacy />}
        />
      </Routes>
    </BrowserRouter>
  )
}

export default App
