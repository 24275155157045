import React from 'react'
import { Link } from 'react-router-dom'

import { Container, Row, Col } from 'react-bootstrap'

function Manifestor() {
    return (
        <div>
            <Container
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh'
                }}>
                <Row style={{ width: '100vw' }} className={'w-100'}>
                    <Col sm={12}>
                        <h1 style={{ width: '200px', textAlign: 'center', fontWeight: 'bolder' }} className={'m-auto'}>
                            {'Manifestor'}
                        </h1>
                    </Col>
                    <Col sm={12} style={{ textAlign: 'center' }} className={'mt-4'}>
                        {'For support contact us at'}&nbsp;
                        <a style={{
                            textDecoration: 'none',
                            color: 'black',
                            fontWeight: 500
                        }}
                            href="mailto:hello@woodandwool.co">
                            {'hello@woodandwool.co'}
                        </a>
                    </Col>
                </Row>
                <Row className={'mt-5'}>
                    <Col sm={12}>
                        <Link style={{
                            textDecoration: 'none',
                            color: 'black'
                        }}
                        to={'/manifestor/privacy'}>{'Privacy policy'}</Link>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Manifestor